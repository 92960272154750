<template>
  <el-form :model="form" ref="form" :rules="rules">
    <!-- 退料入库表格 -->
    <el-table
      border
      stripe
      v-if="applyType != 'JIA'"
      border
      :data="form.tableData"
      class="mt20"
      :key="applyType"
    >
      <el-table-column
        align="center"
        prop="skuId"
        label="产品编码"
        fixed="left"
        width="120"
      >
        <template slot-scope="scope">
          <el-link
            type="priamry"
            :underline="false"
            @click="skuIdClick(scope.row)"
          >
            {{ scope.row.skuId }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="parentName" label="产品名称">
        <template slot-scope="scope">
          <span>
            {{ scope.row.productCn || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecs" label="英文规格" width="150" show-overflow-tooltip="">
        <template slot-scope="scope">
          {{ scope.row.productSpecs || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecsCn" label="中文规格" width="150" show-overflow-tooltip="">
        <template slot-scope="scope">
          {{ scope.row.productSpecsCn || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="productType"
        label="产品类型"
      ></el-table-column>

      <el-table-column align="center" prop="productType" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="warehouseInNum">
        <template slot="header">
          <i style="color: #ff4d4f">*</i>
          实际入库数量
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="'tableData.' + scope.$index + '.warehouseInNum'"
            :rules="[
              {
                required: true,
                message: '实际入库数量必填',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <el-input-number
              v-model="scope.row.warehouseInNum"
              :precision="0"
              :min="1"
              :max="scope.row.maxNum"
            ></el-input-number>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="remark"
        label="操作"
        width="200px"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
          >
            <el-button
              type="danger"
              plain
              icon="el-icon-delete-solid"
              circle
              size="mini"
              @click="deletePro(scope.$index)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 加工入库表格 -->
    <el-table border stripe v-else border :data="form.tableData" class="mt20">
      <el-table-column
        align="center"
        prop="skuId"
        label="产品编码"
        fixed="left"
        width="120"
      >
        <template slot-scope="scope">
          <el-link
            type="priamry"
            :underline="false"
            @click="skuIdClick(scope.row)"
          >
            {{ scope.row.skuId }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="parentName" label="产品名称">
        <template slot-scope="scope">
          <span>
            {{ scope.row.productCn || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecs" label="英文规格" width="150" show-overflow-tooltip="">
        <template slot-scope="scope">
          {{ scope.row.productSpecs || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productSpecsCn" label="中文规格" width="150" show-overflow-tooltip="">
        <template slot-scope="scope">
          {{ scope.row.productSpecsCn || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="productType"
        label="产品类型"
      ></el-table-column>

      <el-table-column align="center" prop="productType" label="单位">
        <template slot-scope="scope">
          {{ scope.row.productUnit || '-' }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="qualityNum">
        <template slot="header">
          <i style="color: #ff4d4f">*</i>
          合格品入库数量
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="'tableData.' + scope.$index + '.qualityNum'"
            :rules="[
              {
                required: true,
                message: '合格品入库数量必填',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <el-input-number
              v-model="scope.row.qualityNum"
              :precision="0"
              :min="0"
              :max="scope.row.maxQualityNum"
            ></el-input-number>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="scrapNum">
        <template slot="header">
          <i style="color: #ff4d4f">*</i>
          报废品入库数量
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="'tableData.' + scope.$index + '.scrapNum'"
            :rules="[
              {
                required: true,
                message: '报废品入库数量必填',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <el-input-number
              v-model="scope.row.scrapNum"
              :precision="0"
              :min="0"
              :max="scope.row.maxScrapNum"
            ></el-input-number>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="remark"
        label="操作"
        width="200px"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
          >
            <el-button
              type="danger"
              plain
              icon="el-icon-delete-solid"
              circle
              size="mini"
              @click="deletePro(scope.$index)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
  import { mixin as productMixin } from '@/views/purchase/outsourceProcess/help/mixin'

  export default {
    name: 'ErpWebNewOutsourceInboundApplyProducts',
    mixins: [productMixin],
    data() {
      return {
        type: 1, // type === 2退料入库 加工入库 type === 1
        form: {
          tableData: [], // 产品数据
        },
        rules: {}, // 校验规则
        applyType: 'JIA',
      }
    },

    methods: {
      initProducts(tableData, applyType) {
        this.applyType = applyType
        if (applyType !== 'JIA') {
          this.form.tableData = tableData.map((item) => {
            item.warehouseInNum = item.maxNum
            return item
          })
        } else {
          this.form.tableData = tableData.map((item) => {
            item.maxQualityNum = item.qualityNum
            item.maxScrapNum = item.scrapNum
            return item
          })
        }
      },

      deletePro(index) {
        let tableData = this.form.tableData
        if (tableData.length === 1) {
          return this.$message.warning('至少保留一条数据')
        }
      },

      getTableData() {
        if (this.applyType == 'TUI') {
          return this.form.tableData
        } else {
          return this.computeTableData()
        }
      },
      computeTableData() {
        let tableData = JSON.parse(JSON.stringify(this.form.tableData)),
          handledTableData = []
        tableData.forEach((pro) => {
          if (pro.scrapNum) {
            //报废品
            handledTableData.push({
              ...pro,
              usable: 0,
              warehouseInNum: pro.scrapNum,
            })
          }
          if (pro.qualityNum) {
            //报废品
            handledTableData.push({
              ...pro,
              usable: 1,
              warehouseInNum: pro.qualityNum,
            })
          }
        })
        return handledTableData
      },
      onNumChange(pro, type) {
        if (pro.scrapNum === 0 && pro.qualityNum === 0) {
          pro[type] = undefined
          return this.$message.warning(
            `${pro.productName}的合格品入库数量和报废品入库数量不能同时为0`
          )
        }
      },

      async validate() {
        return this.$refs.form.validate().then(() => {
          return new Promise((res, rej) => {
            if (this.applyType === 'JIA') {
              let pro = this.form.tableData.find(
                (pro) => pro.scrapNum === 0 && pro.qualityNum === 0
              )

              if (pro) {
                this.$message.warning(
                  `${pro.productName}的合格品入库数量和报废品入库数量不能同时为0`
                )
                rej()
              } else {
                res()
              }
            } else {
              res()
            }
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
